import React, { memo, useState } from "react";
import { DeleteOutline } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { deleteCommentAttachmentForSimulation } from "../../../services/cluster";
import { useMutation, useQueryClient } from "react-query";
import DeleteConfirm from "./DeleteConfirm";

const DeleteAttachment = ({
  fileName,
  simulationCommentId,
  simulationUploadId,
  handleClose,
}) => {
  const [deleteConfirmDialogProps, setShowDeleteConfirmDialogProps] =
    useState(null);
  const queryClient = useQueryClient();

  const { mutate: deleteAttachment } = useMutation(
    deleteCommentAttachmentForSimulation,
    {
      onSuccess: async (data) => {
        console.log("Deletion Complete from S3:", data);
      },
      onError: (err) => {},
      onSettled: () => {
        queryClient.invalidateQueries("fetchCommentsForSimulation");
        queryClient.invalidateQueries("getCommentAttachmentUrls");
      },
    }
  );

  const updateAttachmentsList = () => {
    deleteAttachment({
      simulationUploadId,
      simulationCommentId,
      fileName,
    });
  };

  return (
    <>
      {deleteConfirmDialogProps ? (
        <DeleteConfirm {...deleteConfirmDialogProps} />
      ) : null}
      <IconButton
        size="large"
        color="warning"
        aria-label="Delete attachment"
        title="Delete attachment"
        onClick={() => {
          const onAccept = () => {
            updateAttachmentsList();
            handleClose();
          };
          const onCancel = () => {
            setShowDeleteConfirmDialogProps(null);
          };

          const props = {
            onAccept,
            onCancel,
            show: true,
            itemType: "Attachments",
            item: fileName,
          };
          setShowDeleteConfirmDialogProps(props);
        }}
      >
        <DeleteOutline />
      </IconButton>
    </>
  );
};

export default memo(DeleteAttachment);
