export function ValueChicklet({ index, text, onEditClicked, mode }) {
  if (!!text) {
    return (
      <div
        className="searchvalue valueContainer"
        onClick={() => {
          // event.stopPropagation();
          if (mode !== "editValue" && mode !== "value") onEditClicked(index);
        }}
        style={{ fontSize: "small", textOverflow: "ellipsis" }}
      >
        <div variant="caption">{text}</div>
      </div>
    );
  }
}
