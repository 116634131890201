import React from "react";
import { isValidDate } from "../../../services/Utils";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import { Box, Button, Paper, Popper, TextField } from "@mui/material";
const DateSearch = (props) => {
  const initialDates = {
    from: new Date(new Date().setFullYear("2023")),
    to: new Date(),
  };

  const {
    onValueChanged,
    showSuggestions,
    anchorEl,
    dateObj = initialDates,
    setDateObj,
    suggestionRef,
    setDatePickBox,
    doneButtonRef,
  } = props;

  const handleDateChange = (newValue = null, type) => {
    const newDates = dateObj;
    const newFrom = !!newValue ? new Date(newValue.setHours(0, 0, 0, 0)) : null;
    const newTo = !!newValue
      ? new Date(newValue.setHours(23, 59, 59, 59))
      : null;
    if (newValue !== "undefined" && type !== "undefined" && type === "from") {
      if (moment(newValue).isBefore("2001-01-01")) {
        setDateObj({
          from: newFrom,
          to: newDates.to,
        });
        return;
      }
      if (moment(newValue).isAfter(newDates.to)) {
        setDateObj({
          from: newFrom,
          to: newDates.to,
        });
        return;
      }
      if (!isValidDate(new Date(newValue))) {
        setDateObj({
          from: newFrom,
          to: newDates.to,
        });
        return;
      }
      newDates.from = newFrom;
      setDateObj({ from: newFrom, to: newDates.to });
    } else if (
      newValue !== "undefined" &&
      type !== "undefined" &&
      type === "to"
    ) {
      if (moment(newValue).isBefore(newDates.from)) {
        setDateObj({ from: newDates.from, to: newTo });
        return;
      }
      if (!isValidDate(new Date(newValue))) {
        setDateObj({ from: newDates.from, to: newTo });
        return;
      }
      newDates.to = newTo;
      setDateObj({ from: newDates.from, to: newTo });
    }
  };

  return (
    <>
      <Popper
        open={showSuggestions}
        anchorEl={anchorEl}
        ref={suggestionRef}
        placement="bottom-start"
        style={{ zIndex: 10, paddingTop: "8px" }}
      >
        <Paper elevation={3} style={{ padding: "8px" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box mb={2}>
              {" "}
              <DatePicker
                disabled={false}
                disableFuture
                label="From"
                value={new Date(dateObj.from)}
                onChange={(val) => handleDateChange(val, "from")}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    id="from-date"
                    variant="standard"
                  />
                )}
                onOpen={() => setDatePickBox(true)}
                onClose={() => setDatePickBox(false)}
                maxDate={new Date()}
                required
              />
            </Box>
            <Box>
              <DatePicker
                disabled={false}
                disableFuture
                label="To"
                value={new Date(dateObj.to)}
                onChange={(val) => handleDateChange(val, "to")}
                renderInput={(params) => (
                  <TextField
                    size="small"
                    {...params}
                    id="to-date"
                    variant="standard"
                  />
                )}
                onOpen={() => setDatePickBox(true)}
                onClose={() => setDatePickBox(false)}
                minDate={new Date(dateObj.from)}
                required
              />
            </Box>
          </LocalizationProvider>
          <Button
            ref={doneButtonRef}
            variant="contained"
            style={{ marginTop: "8px" }}
            className="doneBtn"
            onClick={(e) => {
              e.preventDefault();
              onValueChanged([
                {
                  value: dateObj,
                },
              ]);
            }}
          >
            Done
          </Button>
        </Paper>
      </Popper>
    </>
  );
};

export default DateSearch;
