import React, { memo, useState } from "react";
import { IconButton, MenuItem } from "@mui/material";
import { deleteCommentForSimulation } from "../../../services/cluster";
import { useMutation, useQueryClient } from "react-query";
import { Delete } from "@mui/icons-material";
import DeleteConfirm from "./DeleteConfirm";

const DeleteComment = ({ comment, handleClose }) => {
  const [deleteConfirmDialogProps, setShowDeleteConfirmDialogProps] =
    useState(null);
  const simulationCommentId = comment.simulationCommentId;
  const queryClient = useQueryClient();

  const { mutate: deleteCommentFile } = useMutation(
    deleteCommentForSimulation,
    {
      onSuccess: async (data) => {
        console.log("Deletion Complete from S3:", data);
      },
      onError: (err) => {},
      onSettled: () => {
        queryClient.invalidateQueries("fetchCommentsForSimulation");
        queryClient.invalidateQueries("getCommentAttachmentUrls");
      },
    }
  );

  return (
    <>
      {deleteConfirmDialogProps ? (
        <DeleteConfirm {...deleteConfirmDialogProps} />
      ) : null}
      <MenuItem
        onClick={() => {
          const onAccept = () => {
            deleteCommentFile(simulationCommentId);
            handleClose();
          };
          const onCancel = () => {
            setShowDeleteConfirmDialogProps(null);
          };

          const props = {
            onAccept,
            onCancel,
            show: true,
            itemType: "Comments",
            item: comment.comment,
          };
          setShowDeleteConfirmDialogProps(props);
        }}
        disableRipple
      >
        <Delete />
        Delete
      </MenuItem>
    </>
  );
};

export default memo(DeleteComment);
