import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
  Typography,
} from "@mui/material";
import "../../../styles/search.css";
import { formatDisplayDatesForSearch } from "../../../services/Utils";
import { useQuery } from "react-query";
import { getVehicleGroups } from "../../../services/vehicle";
import { SearchItems } from "./SearchItems";
import { getColumnsData } from "../VehicleColumns";
import { getVehicleData } from "../../../services/deployment";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { SearchKeyOptions } from "./SearchKeyOptions";
import SearchInput from "./SearchInput";
import { margin } from "@mui/system";

const NewVehicleSearch = ({
  extraFilters,
  setExtraFilters,
  resetDataTableSelectionItems,
}) => {
  const { data: vehicleGroups = [] } = useQuery(
    "vehicleGroup",
    getVehicleGroups,
    {
      refetchOnWindowFocus: false,
    }
  );

  const vehicleGroup = vehicleGroups.map((i) => {
    return { id: i.vehicleGroupId, value: i.vehicleGroupName };
  });

  const SEARCHKEYOPTIONS = SearchKeyOptions.map((option) => {
    if (option.id === "vehicle.vehicleGroups.vehicleGroupId") {
      return {
        ...option,
        operators: option.operators.map((op) =>
          op.id === "in" ? { ...op, results: vehicleGroup } : op
        ),
      };
    }
    return option;
  });

  const [querySearch, setQuerySearch] = useState(false);
  const [filterItems, setFilterItems] = useState([]);
  const [searchOptions, setSearchOptions] = useState(SEARCHKEYOPTIONS);
  const values = useRef([]);
  const activeItemIndex = useRef(15);
  const mode = useRef("key");
  const inputRef = useRef(null);
  const itemInputRef = useRef(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const suggestionRef = useRef();
  const searchboxref = useRef();
  const savedSelectedOptions = useRef([]);
  const [datePickBox, setDatePickBox] = useState(false);
  // const searchSubmitButtonRef = useRef();
  const fetchAll = true;
  const columns = getColumnsData();
  const queryName = "vehicle";
  const [isLoading, setIsLoading] = useState(false);
  const [suggestionFilter, setSuggestionFilter] = useState([]);
  const [data, setData] = useState({
    loading: true,
    rows: [],
    totalRows: 0,
    rowCount: 0,
    pageSize: 10,
    pageCount: 0,
    page: 1,
    field: "serialNoCreatedAt",
    sort: "desc",
    filterValue: {},
  });
  const { filterValue = {}, page, pageSize, field, sort } = data;
  const { data: { data: { res: paginationData = [] } = {} } = {} } = useQuery(
    [
      queryName,
      page,
      pageSize,
      field,
      sort,
      filterValue,
      columns,
      suggestionFilter,
      fetchAll,
    ],
    getVehicleData,
    {
      onSuccess: (response) => {
        const { data: { meta = {} } = {} } = response;
        setData((prevValues) => ({
          ...prevValues,
          rowCount: meta.itemCount,
          pageCount: meta.pageCount,
        }));
        handleSuggestions(response.data.res, meta.page === 1 ? false : true);
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchboxref.current &&
        !searchboxref?.current?.contains(event.target) &&
        suggestionRef?.current &&
        !suggestionRef?.current?.contains(event.target) &&
        !datePickBox
      ) {
        setShowSuggestions(false);
        setDatePickBox(false);
      }
    };
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [datePickBox]);

  const handleSuggestions = (paginationData, paginationFlag) => {
    if (mode.current === "value" || mode.current === "editValue") {
      const item =
        mode.current === "value"
          ? values.current[values.current.length - 1]
          : values.current[activeItemIndex.current];
      if (item.op.customSuggestion) {
        const suggestionValues = paginationData.map((i) => {
          if (item.key.id === "serialNo") {
            return { id: i.serialNo, value: i.serialNo };
          } else if (item.key.id === "vehicleKey") {
            return {
              id: i.vehicleKey,
              value: i?.vehicleKey,
            };
          }
          return null;
        });
        const finalSuggestionValues = suggestionValues
          .filter((i) => i?.id && i.id.length > 0)
          .filter((value, index, self) =>
            item.key.id === "vehicleKey"
              ? self.findIndex((v) => v.id === value.id) === index ||
                value.value !== self.find((v) => v.id === value.id).value
              : true
          );
        if (
          finalSuggestionValues.length === 0 &&
          suggestionValues.length > 0 &&
          data.page < data.pageCount
        ) {
          setData((prevValues) => ({
            ...prevValues,
            page: prevValues.page + 1,
          }));
        } else {
          if (paginationFlag) {
            setSearchOptions((prevValues) => [
              ...prevValues,
              ...finalSuggestionValues,
            ]);
          } else setSearchOptions([...finalSuggestionValues]);
          setIsLoading(false);
        }
      }
    }
  };

  const onSearchSubmit = () => {
    const getVehicleKeyInFilter = extraFilters?.filter((i) => {
      return i.columnField === "vehicleKey" && i?.value?.item?.length;
    });
    if (getVehicleKeyInFilter?.length) {
      const getVehicleKeyContainsString = filterItems.filter((i) => {
        return i.columnField === "vehicleKey";
      });
      if (getVehicleKeyContainsString?.length) {
        const columnValue = {
          ...getVehicleKeyInFilter?.[0].value,
          ...getVehicleKeyContainsString?.[0].value,
        };
        const vehicleKeyFilterItem = getVehicleKeyInFilter?.[0];
        vehicleKeyFilterItem.value = columnValue;
        vehicleKeyFilterItem.displayValue =
          getVehicleKeyContainsString?.[0]?.displayValue;
        const newFilterItems = filterItems.filter((i) => {
          return i.columnField !== "vehicleKey";
        });
        setExtraFilters([...newFilterItems, ...[vehicleKeyFilterItem]]);
      } else {
        setExtraFilters([...filterItems, ...getVehicleKeyInFilter]);
      }
    } else {
      setExtraFilters([...filterItems]);
    }
    resetDataTableSelectionItems?.();
  };

  const clearSearchForm = () => {
    values.current = [];
    setFilterItems([]);
    setExtraFilters([]);
    mode.current = "key";
    setSearchOptions(SEARCHKEYOPTIONS);
    setShowSuggestions(false);
    activeItemIndex.current = 15;
  };

  const saveFilterItemsToFilterArray = (item) => {
    const { columnField } = item;
    const oldItems = filterItems;
    const newItems = oldItems.filter((i) => i.columnField !== columnField);
    setFilterItems([...newItems, item]);
  };

  const removeFilterItemsFromFilterArray = (columnField) => {
    const newItems = filterItems.filter((i) => i.columnField !== columnField);
    setFilterItems([...newItems]);
  };
  const updateFilterItemsFromFilterArray = ({ columnField, locval, input }) => {
    const oldFilter = filterItems;
    const newFilter = oldFilter.map((i) => {
      if (i.columnField === columnField) {
        if (!input) return { ...i, operatorValue: locval, value: locval };
        return { ...i, value: locval };
      }
      return i;
    });
    setFilterItems(newFilter);
  };

  const onSelectValueChanged = (selectedOptions) => {
    if (selectedOptions.length === 0) {
      return;
    }
    const prevValues = values.current;
    let lastItem = prevValues[prevValues.length - 1];
    if (mode.current === "key") {
      values.current = [...prevValues, { key: selectedOptions[0] }];
    } else if (mode.current === "operator") {
      lastItem["op"] = selectedOptions[0];
      values.current = [...prevValues];
    } else if (mode.current === "value") {
      if (lastItem.op.isDate) {
        lastItem["val"] = {
          id: lastItem.key.id,
          value: {
            from: new Date(selectedOptions[0].value.from).toISOString(),
            to: new Date(selectedOptions[0].value.to).toISOString(),
          },
        };
      } else {
        lastItem["val"] = selectedOptions;
      }
      values.current = [...prevValues];
    } else if (mode.current === "editValue") {
      const oldValues = values.current;
      const newValues = oldValues.map((item, index) => {
        if (index === activeItemIndex.current) {
          if (item.op.isDate) {
            item.val = {
              id: item.val.id,
              value: {
                from: new Date(selectedOptions[0].value.from).toISOString(),
                to: new Date(selectedOptions[0].value.to).toISOString(),
              },
            };
          } else item.val = selectedOptions;
        }
        return item;
      });
      const columnField = values.current[activeItemIndex.current].key.id;
      const input = values.current[activeItemIndex.current].op.input;
      let locval;
      if (values.current[activeItemIndex.current].op.isDate) {
        locval = newValues[activeItemIndex.current].val.value;
      } else {
        locval =
          selectedOptions.length > 1 ||
          values.current[activeItemIndex.current].op.isMultiSelect
            ? selectedOptions.map((i) => {
                return i.id;
              })
            : selectedOptions[0].id;
      }
      updateFilterItemsFromFilterArray({ columnField, locval, input });
      activeItemIndex.current = 15;
      values.current = [...newValues];
    }
    modeChange();
  };

  const onItemEdited = (index) => {
    mode.current = "editValue";
    activeItemIndex.current = index;
    setQuerySearch(false);
    setIsLoading(true);
    savedSelectedOptions.current = getSelectedOptions();
    handleEditMode();
  };

  const onEditCancel = () => {
    activeItemIndex.current = 15;
    modeChange();
    console.log(activeItemIndex.current);
  };

  const onItemRemoved = (index) => {
    const columnField = values.current[index].key.id;
    values.current.splice(index, 1);
    if (mode.current === "key") {
      let keyOptions = SEARCHKEYOPTIONS.filter(
        (ob) => values.current.map((ob) => ob.key.id).indexOf(ob.id) === -1
      );
      setSearchOptions(keyOptions);
    }
    activeItemIndex.current = 15;
    modeChange();
    removeFilterItemsFromFilterArray(columnField);
    setIsLoading(false);
  };

  const onPartItemRemove = () => {
    if (values.current.length > 0) {
      const len = values.current.length - 1;
      if (mode.current === "key") {
        onItemEdited(len);
      } else if (
        mode.current === "value" ||
        (mode.current === "editValue" && activeItemIndex.current === len)
      ) {
        const newValues = values.current.map((item, index) => {
          if (index === len) {
            const { val, op, ...rest } = item;
            removeFilterItemsFromFilterArray(item.key.id);
            return rest;
          }
          return item;
        });
        mode.current = "operator";
        values.current = [...newValues];
        activeItemIndex.current = 15;
        modeChange();
      } else if (mode.current === "operator") {
        onItemRemoved(len);
      } else {
        console.log("Cant backspace from editmode!");
      }
    }
  };

  const getSelectedOptions = () => {
    if (mode.current === "editValue") {
      let opts =
        (values.current[activeItemIndex.current] &&
          values.current[activeItemIndex.current].val) ||
        [];
      if (values.current[activeItemIndex.current].op?.isDate) {
        opts = [opts];
      }
      return [...opts];
    }
    return [];
  };

  const modeChange = () => {
    setQuerySearch(false);
    if (!values.current.length) {
      mode.current = "key";
      setSearchOptions(SEARCHKEYOPTIONS);
      inputRef?.current?.focus();
      return;
    }
    let lastValue = values.current[values.current.length - 1];
    if (lastValue.key && lastValue.op && lastValue.val) {
      if (mode.current !== "editValue") {
        convertToFiltersItems(values);
      }
      let keyOptions = SEARCHKEYOPTIONS.filter(
        (ob) => values.current.map((ob) => ob.key.id).indexOf(ob.id) === -1
      );
      setSearchOptions(keyOptions);
      mode.current = "key";
    } else if (lastValue.key && lastValue.op) {
      mode.current = "value";
      setSearchOptions(lastValue.op.results || []);
      if (lastValue.op.customSuggestion) {
        setSuggestionFilter([]);
        setData((prevValues) => ({
          ...prevValues,
          filterValue: {
            items: [
              {
                value: "isNotEmpty",
                operatorValue: "isNotEmpty",
                columnField: lastValue.key.id,
              },
            ],
          },
        }));
        if (data.page !== 1) {
          setData((prevValues) => ({
            ...prevValues,
            page: 1,
          }));
        } else {
          setIsLoading(true);
          handleSuggestions(paginationData, false);
        }
      }
    } else if (lastValue.key) {
      mode.current = "operator";
      var item = SEARCHKEYOPTIONS.filter((i) => {
        if (i.id === lastValue.key.id) {
          return true;
        }
        return false;
      });
      setSearchOptions(item[0].operators);
    }
    inputRef?.current?.focus();
  };

  const handleEditMode = () => {
    var editedItem = values.current[activeItemIndex.current];
    setSearchOptions(editedItem.op.results || []);
    if (editedItem.op.customSuggestion) {
      setSuggestionFilter([]);
      if (data.page !== 1) {
        setData((prevValues) => ({
          ...prevValues,
          page: 1,
        }));
      } else handleSuggestions(paginationData, false);
    }
    setIsLoading(false);
  };
  const convertToFiltersItems = (values) => {
    if (!values.current || values.current.length === 0) {
      return;
    }
    const item = values.current[values.current.length - 1];
    if (item.op && item.val) {
      let locval;
      if (item.op.isDate) {
        locval = item.val.value;
      } else {
        locval =
          item.op.isMultiSelect || item.val.length > 1
            ? item.val.map((i) => {
                return i.id;
              })
            : item.val[0].id;
      }
      let colfield = item.op.input ? item.op.id : locval;
      saveFilterItemsToFilterArray({
        value: locval,
        operatorValue: colfield,
        columnField: item.key.id,
        label: item.key.value,
        displayValue: formatDisplayDatesForSearch(locval),
      });
    }
  };

  const searchInputProps = {
    mode: mode.current,
    activeItemIndex: activeItemIndex.current,
    searchOptions: searchOptions,
    values: values.current,
    data: data,
    inputRef: inputRef,
    suggestionRef: suggestionRef,
    isLoading: isLoading,
    showSuggestions: showSuggestions,
    savedSelectedOptions: savedSelectedOptions,
    setIsLoading: setIsLoading,
    setData: setData,
    setSearchOptions: setSearchOptions,
    setDatePickBox: setDatePickBox,
    setShowSuggestions: setShowSuggestions,
    setSuggestionFilter: setSuggestionFilter,
    onSelectValueChanged: onSelectValueChanged,
    onSearchSubmit: onSearchSubmit,
    onPartItemRemove: onPartItemRemove,
    getSelectedOptions: getSelectedOptions,
  };

  return (
    <Grid item xs={12}>
      <Card sx={{ overflow: "visible" }}>
        <Grid item xs={12} md={12}>
          <Box p="0" display="flex" flexDirection="column" alignItems="center">
            <Box
              position="relative"
              width="100%"
              display="flex"
              alignItems="center"
              border="1px solid rgba(0, 0, 0, 0.23)"
              borderRadius="4px"
              ref={searchboxref}
              p="8px"
              onClick={() => {
                setShowSuggestions(true);
                inputRef?.current?.focus();
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                rowGap={"7px"}
                flexGrow={1}
              >
                {values.current.map(
                  (item, index) =>
                    index <= activeItemIndex.current && (
                      <SearchItems
                        itemInputRef={itemInputRef}
                        key={index}
                        index={index}
                        searchKey={item.key?.value}
                        operator={item.op ? item.op : ""}
                        searchVal={item.val ? item.val : ""}
                        onItemRemoved={onItemRemoved}
                        onItemEdited={onItemEdited}
                        mode={mode.current}
                        activeItemIndex={activeItemIndex.current}
                        querySearch={querySearch}
                        searchInputProps={searchInputProps}
                        onEditCancel={onEditCancel}
                      />
                    )
                )}
                {mode.current === "key" && (
                  <SearchInput {...searchInputProps} />
                )}
              </Box>
              <CardActions sx={{ padding: "3px" }}>
                <Button
                  disabled={mode.current === "editValue"}
                  variant="outlined"
                  onClick={() => {
                    setShowSuggestions(false);
                    setQuerySearch(true);
                    onSearchSubmit();
                  }}
                >
                  <SearchIcon />
                </Button>
                <Button variant="contained" onClick={() => clearSearchForm()}>
                  <RestartAltIcon />
                </Button>
              </CardActions>
            </Box>
            {/* <div style={{ width: "100%", paddingLeft: "4px" }}>
              <Typography
                variant="caption"
                fontSize={"0.8em"}
                style={{ textAlign: "left" }}
              >
                Error : invalid query
              </Typography>
            </div> */}
          </Box>
        </Grid>
      </Card>
    </Grid>
  );
};

export default NewVehicleSearch;
