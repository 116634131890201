import React, { useEffect, useMemo, useRef, useState } from "react";
import DateSearch from "./DateSearch";
import useDebounce from "../../../hooks/useDebounce";
import {
  alpha,
  Button,
  CircularProgress,
  ListItemText,
  Paper,
  Popper,
  Radio,
  selectClasses,
  Typography,
  useTheme,
} from "@mui/material";
import "./../../../styles/search.css";

const SearchInput = ({
  mode,
  activeItemIndex,
  searchOptions,
  values,
  data,
  inputRef,
  suggestionRef,
  isLoading,
  showSuggestions,
  setIsLoading,
  setData,
  setSearchOptions,
  setDatePickBox,
  setShowSuggestions,
  setSuggestionFilter,
  onSelectValueChanged = (option) => {},
  onSearchSubmit = (option) => {},
  onPartItemRemove = (option) => {},
  getSelectedOptions = (option) => {},
  savedSelectedOptions,
  // searchSubmitButtonRef,
}) => {
  const placeholderText =
    mode === "key" ? "Search Key" : mode === "operator" ? "Operator" : "Value";
  const [query, setQuery] = useState("");
  const suggestionRefs = useRef([]);
  const typeDelay = useDebounce(query, 500);
  const [selectedOptions, setSelectedOptions] = useState(getSelectedOptions());
  const theme = useTheme();
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const doneButtonRef = useRef();

  useEffect(() => {
    resetSearchInput();
    if (mode === "editValue") {
      const tempSel = savedSelectedOptions.current;
      setSelectedOptions(tempSel);
      SELECTEDITEMS.current = tempSel?.reduce((acc, item) => {
        acc[item.id] = item.id;
        return acc;
      }, {});
    }
  }, [mode, savedSelectedOptions]);

  useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, [inputRef]);

  const isDate =
    (mode === "value" || mode === "editValue") &&
    (activeItemIndex < values.length
      ? values[activeItemIndex].op.isDate
      : values[values.length - 1]?.op.isDate);

  const isMultiSelect =
    (mode === "value" || mode === "editValue") &&
    (activeItemIndex < values.length
      ? values[activeItemIndex].op.isMultiSelect
      : values[values.length - 1]?.op.isMultiSelect);

  const customSuggestion =
    (mode === "value" || mode === "editValue") &&
    (activeItemIndex < values.length
      ? values[activeItemIndex].op?.customSuggestion
      : values[values.length - 1]?.op?.customSuggestion);
  const customInput =
    (mode === "value" || mode === "editValue") &&
    (activeItemIndex < values.length
      ? values[activeItemIndex].op?.customInput
      : values[values.length - 1]?.op?.customInput);

  const initialDates = {
    from: new Date(new Date().setFullYear("2023")),
    to: new Date(),
  };

  const [dateObj, setDateObj] = useState(
    JSON.parse(JSON.stringify(initialDates))
  );

  const filteredOptions = useMemo(() => {
    if (!customSuggestion) {
      return searchOptions.filter(({ value }) =>
        value?.toLowerCase()?.startsWith(query.toLowerCase())
      );
    }
    return searchOptions;
  }, [query, searchOptions, customSuggestion]);

  const options = filteredOptions;

  useEffect(() => {
    const handleFilterChangeRef = (data) => {
      if (mode === "value" || mode === "editValue") {
        const item =
          mode === "value"
            ? values[values.length - 1]
            : values[activeItemIndex];
        if (item["op"].customSuggestion) {
          setIsLoading(true);
          setSearchOptions([]);
          setData((prevValues) => ({
            ...prevValues,
            page: 1,
          }));
          setSuggestionFilter([
            {
              value: data,
              operatorValue: "startsWith",
              columnField: item.key.id,
              label: item.key.value,
              displayValue: data,
            },
          ]);
        }
      }
    };
    handleFilterChangeRef(typeDelay);
  }, [
    typeDelay,
    mode,
    values,
    activeItemIndex,
    setIsLoading,
    setSearchOptions,
    setData,
    setSuggestionFilter,
  ]);

  const handleKeyPress = (e) => {
    if (e.key === "Escape") {
      setShowSuggestions(false);
    } else if (e.key === " " && activeSuggestionIndex !== -1 && isMultiSelect) {
      e.preventDefault();
      onOptionSelected(suggestionRefs?.current[activeSuggestionIndex]?.option);
    } else if (e.key === "ArrowDown" && showSuggestions) {
      e.preventDefault();
      setActiveSuggestionIndex(
        activeSuggestionIndex < options.length - 1
          ? activeSuggestionIndex + 1
          : activeSuggestionIndex
      );
      suggestionRefs?.current[
        activeSuggestionIndex < options.length - 1
          ? activeSuggestionIndex + 1
          : activeSuggestionIndex
      ]?.element?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    } else if (e.key === "ArrowUp" && showSuggestions) {
      e.preventDefault();
      setActiveSuggestionIndex(
        activeSuggestionIndex > 0 ? activeSuggestionIndex - 1 : -1
      );
      suggestionRefs?.current[
        activeSuggestionIndex > 0 ? activeSuggestionIndex - 1 : -1
      ]?.element?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    } else if (query.length === 0 && e.key === "Backspace") {
      setShowSuggestions(true);
      setSelectedOptions([]);
      onPartItemRemove();
    }
  };

  const enterKeyPress = (e) => {
    if (showSuggestions) {
      if (e.key === "Enter") {
        if ((isMultiSelect && selectedOptions.length > 0) || isDate) {
          if (doneButtonRef?.current) {
            doneButtonRef.current.click();
          }
        } else if (customInput && query.length > 0) {
          onSelectValueChanged([{ id: query, value: query }]);
        } else if (
          !isMultiSelect &&
          options.length > 0 &&
          activeSuggestionIndex !== -1
        ) {
          if (suggestionRefs?.current)
            suggestionRefs?.current[activeSuggestionIndex]?.element.click();
        }
      }
    } else if (e.key === "Enter" && mode === "key") {
      onSearchSubmit();
    }
  };

  const dateSearchProps = {
    onValueChanged: onSelectValueChanged,
    showSuggestions: showSuggestions,
    anchorEl: inputRef?.current,
    dateObj: dateObj,
    suggestionRef: suggestionRef,
    setDateObj: setDateObj,
    setDatePickBox: setDatePickBox,
    doneButtonRef: doneButtonRef,
  };

  const handleMore = () => {
    setIsLoading(true);
    setData((prevValues) => ({
      ...prevValues,
      page: prevValues.page + 1,
    }));
  };

  const SELECTEDITEMS = useRef(
    selectedOptions?.reduce((acc, item) => {
      acc[item.id] = item.id;
      return acc;
    }, {})
  );

  const onOptionSelected = (option) => {
    let newOptions = [];
    if (isMultiSelect) {
      if (SELECTEDITEMS.current[option?.id]) {
        newOptions = selectedOptions.filter((i) => {
          if (i.id !== option.id) {
            return true;
          }
          return false;
        });
        SELECTEDITEMS.current[option?.id] = undefined;
      } else {
        SELECTEDITEMS.current[option?.id] = option?.id;
        newOptions = [...selectedOptions, option];
      }
    } else {
      SELECTEDITEMS.current = {};
      SELECTEDITEMS.current[option?.id] = option?.id;
      newOptions = [option];
    }
    setSelectedOptions(newOptions);
    handleOptionClick(newOptions);
  };

  const resetSearchInput = () => {
    setActiveSuggestionIndex(0);
    setQuery("");
    SELECTEDITEMS.current = {};
    setSelectedOptions([]);
  };

  const handleOptionClick = (newOptions) => {
    if (newOptions.length > 0 && !isMultiSelect) {
      onSelectValueChanged(newOptions);
    }
  };

  return (
    <>
      <input
        ref={inputRef}
        type="text"
        placeholder={placeholderText}
        onChange={(e) => {
          if (!isDate) {
            setQuery(e.target.value);
          }
          setActiveSuggestionIndex(0);
          setShowSuggestions(true);
        }}
        onFocus={(e) => {}}
        style={{
          padding: "4px 8px 8px 4px",
          backgroundColor: "white",
          width: mode === "key" ? "auto" : "75px",
          border: "none",
          outline: "none",
        }}
        value={query}
        onKeyDown={(e) => {
          handleKeyPress(e);
        }}
        onKeyUp={(e) => {
          enterKeyPress(e);
        }}
      />
      {isDate ? (
        <DateSearch {...dateSearchProps} />
      ) : (
        inputRef.current && (
          <Popper
            open={(showSuggestions && options.length > 0) || isLoading}
            anchorEl={inputRef.current || undefined}
            placement="bottom-start"
            style={{ zIndex: 10 }}
            ref={suggestionRef}
          >
            <Paper
              sx={{ marginTop: "9px" }}
              onClick={() => {
                inputRef?.current?.focus();
              }}
            >
              {isLoading ? (
                <div
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    minWidth: "100px",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <ul
                    className={`searchResults ${
                      options.length > 0 ? "show" : "hidden"
                    }`}
                    style={{ maxHeight: "333px", overflowY: "auto" }}
                  >
                    {options.map((option, optIndex) => {
                      return (
                        <li
                          ref={(el) =>
                            (suggestionRefs.current[optIndex] = {
                              element: el,
                              option,
                            })
                          }
                          style={{
                            paddingLeft: "0px",
                            height: "32px",
                            maxWidth: "250px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            backgroundColor:
                              optIndex === activeSuggestionIndex
                                ? " #ebebeb"
                                : "",
                          }}
                          key={option.id}
                          onClick={() => onOptionSelected(option)}
                          title={option.value}
                        >
                          <input
                            style={{ marginRight: "8px" }}
                            type={isMultiSelect ? "checkbox" : "radio"}
                            id={`option-${optIndex}`}
                            name="options"
                            value={option.value}
                            checked={SELECTEDITEMS.current[option?.id] || false}
                          />
                          <ListItemText primary={option.value} />
                        </li>
                      );
                    })}
                    {customSuggestion &&
                      data.rowCount !== options.length &&
                      data.page < data.pageCount && (
                        <div onClick={handleMore} className="showMore">
                          <Typography
                            color={theme.palette.primary.main}
                            sx={{ fontWeight: "bold" }}
                          >
                            Show More
                          </Typography>
                        </div>
                      )}
                  </ul>
                  {isMultiSelect && (
                    <Button
                      ref={doneButtonRef}
                      variant="outlined"
                      className="doneBtn"
                      size="small"
                      onClick={(e) => {
                        if (selectedOptions.length > 0) {
                          e.preventDefault();
                          onSelectValueChanged(selectedOptions);
                          resetSearchInput();
                        }
                      }}
                    >
                      Done
                    </Button>
                  )}
                </>
              )}
            </Paper>
          </Popper>
        )
      )}
    </>
  );
};

export default SearchInput;
